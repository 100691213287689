import React from "react"
// import { StaticImage } from "gatsby-plugin-image"

import CircleDown from "@images/circled-angle-down-lightblue.svg"

const AboutAccordion = ({ accordionItem, index, toggleAccordion }) => {
  // console.log("accordionItem.open", accordionItem.open)
  return (
    <>
      <div
        className={`accordion ${accordionItem.open ? "open" : ""}`}
        key={index}
        onClick={() => toggleAccordion(index)}
      >
        <div className="row">
          <div className="col-lg-5 col-md-5 relative d-flex justify-content-center align-items-center">
            <div
              className="image-wrapper position-absolute"
              style={{ top: "5rem" }}
            >
              <img
                src={`../assets/${accordionItem.emblem}`}
                style={{
                  objectFit: "contain",
                  width: "100%",
                  height: "auto",
                }}
              />
            </div>
          </div>
          <div className="col-lg-7 col-md-6 position-relative ms-auto">
            <div className="d-flex justify-content-between accordion-title-wrapper position-relative">
              <div className="accordion-title">{accordionItem.title}</div>
              <img src={CircleDown} alt="circle down" className="chevron" />
            </div>
            <div
              className="accordion-content"
              style={{ fontSize: "1.30rem" }}
              dangerouslySetInnerHTML={{
                __html: accordionItem.content,
              }}
            />
          </div>
        </div>
      </div>
    </>
  )
}

export default AboutAccordion
