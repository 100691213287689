import React from "react"
import { Link } from "gatsby"

//swiper
import Swiper from "react-id-swiper"
import "swiper/css/swiper.css"

import { GatsbyImage } from "gatsby-plugin-image"

import ChevronLeft from "@images/circled-arrow-left.svg"
import ChevronRight from "@images/circled-arrow-right.svg"

//icon
// import VideoIcon from "@images/video-icon-sm.svg"

const slugify = require("slugify")

const BlogSwiperLatest = ({ latestPosts }) => {
  const swiperSettings = {
    loop: true,
    // spaceBetween: 100,
    speed: 1000,
    // autoplay: {
    //   delay: 5000,
    // },
    autoplay: false,
    disableOninteraction: false,
    navigation: {
      nextEl: "#article-next",
      prevEl: "#article-prev",
    },
    // pagination: {
    //   el: ".swiper-pagination",
    //   type: "bullets",
    //   // dynamicBullets: true,
    //   clickable: true,
    // },
    fadeEffect: {
      crossFade: true,
    },
    breakpoints: {
      1366: {
        slidesPerView: 3,
        spaceBetween: 20,
      },
      991.98: {
        slidesPerView: 1,
        spaceBetween: 20,
      },
      768: {
        slidesPerView: 1,
      },
      767: {
        slidesPerView: 1,
      },
    },
  }

  return (
    <>
      <div className="blog-swiper mt-5">
        <div className="fullwidth-nav align-items-center">
          <h2 className="display-4 me-auto pb-3" style={{ fontWeight: 700 }}>
            Latest Posts
          </h2>
          <div className="me-2" id="article-prev">
            <img src={ChevronLeft} alt="Chevron Left" width={40} height={40} />
          </div>
          <div id="article-next">
            <img
              src={ChevronRight}
              alt="Chevron Right"
              width={40}
              height={40}
            />
          </div>
        </div>
        <Swiper {...swiperSettings}>
          {/* <BackgroundImage
              Tag="div"
              {...bgImage}
              // fluid={imageData}
              // className="hero-banner"
              preserveStackingContext
              style={{ backgroundPosition: "center", padding: "20rem 0" }}
            >
              Testing only
            </BackgroundImage> */}
          {latestPosts.map(({ node }) => (
            <div>
              <div className="row">
                <div className="col-lg-12">
                  <div className="blog-item">
                    <Link to={`/${node.slug.current}`}>
                      <div
                        className="
                      blog-image-wrapper
                      image-wrapper-rectangle
                      mb-3
                      rounded-25
                    "
                      >
                        <GatsbyImage
                          image={
                            node.mainImage
                              ? node.mainImage.asset.gatsbyImageData
                              : ""
                          }
                          style={{
                            gridArea: "1/1",
                            zIndex: 1,
                            position: "absolute",
                            height: "100%",
                            width: "100%",
                            borderRadius: 25,
                          }}
                        />
                        <div
                          style={{
                            gridArea: "1/1",
                            placeItems: "center",
                          }}
                        />
                        {/* <div className="blog-item-type" title="video">
                          <img src={VideoIcon} alt="Video icon" />
                        </div> */}
                      </div>
                    </Link>
                    <div className="blog-text">
                      <div className="blog-item-categories">
                        {node?.categories?.map(cat => (
                          <Link
                            to={`/category/${slugify(cat.title.toLowerCase())}`}
                            className="blog-category-tag"
                          >
                            <span
                              className={`category-color ${(cat.title ===
                                "Business" &&
                                "business-color") ||
                                (cat.title === "Thought Provoking" &&
                                  "thought-color") ||
                                (cat.title === "Newsworthy" && "news-color") ||
                                (cat.title === "Education" && "edu-color") ||
                                (cat.title === "Featured" && "feat-color") ||
                                (cat.title === "Founder Perspective" &&
                                  "founder-color") ||
                                (cat.title === "Health & Wellness" &&
                                  "health-color") ||
                                (cat.title === "Inspiration" &&
                                  "inspi-color") ||
                                "default-color"}`}
                            />
                            {cat.title}
                          </Link>
                        ))}
                      </div>
                      <Link
                        to={`/${node.slug.current}`}
                        className="link-unstyled"
                      >
                        <h3
                          className="h4 mt-2 mb-3 blog-item-title"
                          style={{ fontWeight: "600" }}
                        >
                          {node.title}
                        </h3>{" "}
                      </Link>
                      <span className="d-block small blog-item-details">
                        {node.authors.length !== 0 ? "Posted by" : ""}
                        {node.authors.map(author => (
                          <Link
                            to={`/author/${author?.person?.slug.current}`}
                            className="link-unstyled me-2"
                            style={{ fontWeight: 700 }}
                          >
                            <span className="ps-2 pe-2">
                              {author?.person?.name}
                            </span>
                            <span style={{ color: "#000" }}>•</span>
                          </Link>
                        ))}
                        {node.publishedAt ? (
                          <div className="d-inline-block">
                            <span>{node?.publishedAt}</span>
                          </div>
                        ) : null}
                        {node.minutes ? (
                          <span className="ms-2">
                            <span className="me-2">•</span>`{node?.minutes} min
                            read`
                          </span>
                        ) : null}
                      </span>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          ))}
        </Swiper>
      </div>
    </>
  )
}

export default BlogSwiperLatest
