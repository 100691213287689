import React, { useState } from "react"
import Layout from "@components/layout"
import { Link, graphql } from "gatsby"
import { LazyLoadComponent } from "react-lazy-load-image-component"
import Seo from "../components/seo"

//images
import LeftTriangleImg from "../images/left-triangle-our-why.png"
import { getImage, StaticImage } from "gatsby-plugin-image"
import { BgImage } from "gbimage-bridge"
// import CircleDown from "@images/circled-angle-down-lightblue.svg"

//icons
import HeartIcon from "@images/heart-icon.svg"
import VolunteerIcon from "@images/volunteer-icon.svg"

//swiper
import BlogSwiperLatest from "@components/swiper/blog-swiper-latest"

//accordion
import AboutAccordion from "../components/accordion/about-accordion"

const About = ({ location, data }) => {
  const latestPosts = data.allSanityPost.edges
  const [accordion, setAccordion] = useState([
    {
      title: "Purposeful Path",
      content:
        "<p>A labyrinth is a symbol of a purposeful path. In our lives we make many journeys to that which is within and return into the world with a broadened understanding of Who-We-Are. We are all on our own paths, exactly where we need to be.</p>",
      emblem: "purposeful-img.png",
      open: true,
    },
    {
      title: "Eternal Growth",
      content:
        "<p>A circle has no beginning and no end, just like the Divine Life-Force or Spirit that keeps our reality in motion. From death to birth, we are in a constant rotation through which we evolve. This circle is our cycle of growth, always reminding us of our connection, unity, oneness, and the love that protects us all.</p>",
      emblem: "eternal-growth.png",
      open: false,
    },
    {
      title: "Journey Together",
      content:
        "<p>A compass can bring a lot of motivation to someone feeling lost. It instills a sense of hope and inspiration to our youth that they can always find their true north. When moving into the next chapter in our lives, we often discover things are way outside our comfort zone. And with a little guidance, even into unfamiliar territory, our journey together becomes safer and more assured.</p>",
      emblem: "journey.png",
      open: false,
    },
    {
      title: "Flow of Creation",
      content:
        "<p>Like the yin-yang symbol, the motion of our emblem is spiraling like a vortex, representing the fusion and flow of dynamic opposites. Always balanced and in harmony.</p><p>A vortex is nature’s most efficient deployer of energy and most favored pattern of growth. From micro to macro, it influences, protects, and supports life. This balance of Energy spirals all around us and within us, patterning our very existence. A reminder that life is always in a state of flux presenting opportunities to re-awaken, to discover, to grow and transform.</p>",
      emblem: "flow.png",
      open: false,
    },
    {
      title: "Double Helix",
      content:
        "<p>Flipped to face each other, the two F’s of the “FLUO Foundation” remind us of the vortex-like double helix structure of our DNA––a symbol of the transpersonal nature of FLUO.</p><p>From tiny vortices of sub-atomic particles to the spiraling “islands” of universes and galaxies, the very flow of energy that creates worlds is not only inside us, but all around us.  We remember that the very conditions for creation and the power to our transformation lies within us and connects us.</p>",
      emblem: "double-helix.png",
      open: false,
    },
  ])

  const toggleAccordion = index => {
    setAccordion(
      accordion.map((accordionItem, i) => {
        if (i === index) {
          accordionItem.open = !accordionItem.open
        } else {
          accordionItem.open = false
        }
        return accordionItem
      })
    )
  }

  const emblemImg = getImage(data.emblemImg)
  const aboutHeroImg = getImage(data.aboutHeroImg)

  return (
    <Layout location={location}>
      <Seo title="About Us" />
      <div className="about-us-wrapper">
        <BgImage image={aboutHeroImg} className="w-100 h-100">
          <div id="about" className="text-white">
            <div className="container">
              <span
                className="text-uppercase text-lightblue"
                style={{ fontWeight: 700 }}
              >
                Who We Are
              </span>
              <h1 className="display-3 mb-5" style={{ fontWeight: 800 }}>
                About Us
              </h1>
              <div className="row justify-content-between">
                <div className="col-md-8 col-xl-6 mb-5 mb-lg-0">
                  <div className="d-grid gap-4">
                    <p className="lead">
                      With an education, early intervention, and prevention
                      philosophy at the forefront of our efforts, the FLUO
                      Foundation seeks to ensure our youth have the inspiration
                      and support they need to navigate life’s often daunting
                      challenges.
                    </p>
                    <p className="lead">
                      We advocate for the holistic well-being of young
                      individuals and believe a continuum mindset of mental
                      health best takes into care their emotional, cultural,
                      spiritual, and psychological differences and needs. As we
                      shine a light in the darkest of places, we are driven to
                      create immersive experiences that connect us, promote
                      community, and decrease the stigma surrounding mental
                      health.
                    </p>
                    <p className="lead">
                      As an independent and donor-funded nonprofit, The FLUO
                      Foundation is transparent and committed to creating
                      profound improvements in education, prevention, and
                      support while investing in a community that enables
                      therapeutic discovery.
                    </p>
                    <div className="d-flex flex-lg-wrap flex-md-wrap flex-wrap">
                      <Link
                        className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center me-4 mb-4"
                        role="button"
                        to="/donate/"
                        style={{ width: 186, height: 54, fontWeight: 600 }}
                      >
                        Donate
                        <img
                          src={HeartIcon}
                          alt="Heart Icon"
                          className="ms-2"
                        />
                      </Link>
                      <Link
                        className="btn btn-primary volunteer-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mt-lg-0 mt-md-0 d-block"
                        role="button"
                        to="/volunteer/"
                        style={{ width: 186, height: 54, fontWeight: 600 }}
                      >
                        Volunteer
                        <img
                          src={VolunteerIcon}
                          alt="Volunteer Icon"
                          className="ms-2"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BgImage>
        {/* <LazyLoadComponent> */}
        <div id="our-why" className="our-why-section" style={{ top: 0 }}>
          <div className="left-triangle-img">
            <img className="w-100" src={LeftTriangleImg} alt="Left triangle" />
          </div>
          <div className="container">
            <div className="row">
              <div className="col-md-6 offset-md-6 mb-5 mb-md-0">
                <span
                  className="font-weight-bold text-uppercase text-lightblue"
                  style={{ fontWeight: 700 }}
                >
                  What We Do
                </span>
                <h2 className="display-3 mb-5" style={{ fontWeight: 800 }}>
                  Our Why
                </h2>
                <div className="d-grid gap-4">
                  <p className="lead" style={{ fontWeight: 400 }}>
                    At the FLUO Foundation, we believe our overall well-being
                    and the freedom to feel alive is a birthright of humanity.
                    Our purpose is to elevate the experience of life for young
                    individuals who are struggling to find their place in the
                    world, to empower them, provide support, and inspire them to
                    live a life full of meaning and purpose. 
                  </p>
                  <p className="lead" style={{ fontWeight: 400 }}>
                    The world can be very demoralizing for our youth, especially
                    with one crisis, shooting, or pandemic after the next.  The
                    impact of trauma and neglect is compounding a mental health
                    crisis with such severity that the consequences are becoming
                    quite literally, existential.  The WHO reports that suicide
                    is now the second leading cause of death among
                    15–29-year-olds, globally.
                  </p>
                  <p className="lead" style={{ fontWeight: 400 }}>
                    We can bury each other in statistics about how serious the
                    ­mental health crisis has become, and at the same time boast
                    about how much progress has been made developing effective
                    treatments.  But there is no debating that our children are
                    not statistics, nor will we be able to treat our way out of
                    this crisis. For us, prevention is treatment.  
                  </p>
                  <p className="lead" style={{ fontWeight: 400 }}>
                    The FLUO Foundation is self-governed and sustained by donors
                    who believe we must address and educate about the primary
                    source of the decline in mental health, that the damage and
                    disability in the lives of our youth is preventable, and
                    that we are the right community to offer a support system
                    that nurtures and offers an inclusive environment free of
                    judgment and rich in acceptance. Our minds matter. Our
                    well-being matters. It’s time to take action.
                  </p>
                </div>
              </div>
            </div>
            <div className="row justify-content-around py-spacing why-us-boxes">
              <LazyLoadComponent>
                <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
                  <div className="cta-box donation-cta text-center text-white rounded-25 offset-cta-box">
                    <StaticImage
                      style={{
                        gridArea: "1/1",
                        borderRadius: 25,
                        zIndex: 1,
                        content: "",
                        position: "absolute",
                        top: 0,
                        left: 0,
                        width: "100%",
                        height: "100%",
                      }}
                      layout="fullWidth"
                      placeholder="blurred"
                      // aspectRatio={3 / 1}
                      alt="Category Banner"
                      src={"../images/donation-cta-bg.png"}
                      formats={["auto", "webp", "avif"]}
                    />
                    <div className="content-wrapper" style={{ padding: 0 }}>
                      <h2 className="font-weight-bold">Make a Donation</h2>
                      {/* <p className="mt-4 mb-4">
                          While 1 in 5 people will experience a mental illness
                          during their lifetime, everyone faces challenges in
                          life that can impact their mental health. Join us in
                          supporting the effort in promoting mental health for
                          all!
                        </p> */}
                      <p className="mt-4 mb-4">
                        Driven by a bold vision for the profound transformation
                        in the mental health of young individuals, the FLUO
                        Foundation relies on the charitable donations of those
                        who are ready to rally behind our youth.
                      </p>
                      <Link
                        className="btn btn-primary donate-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                        role="button"
                        to="/donate/"
                        style={{ width: 186, height: 54, fontWeight: 600 }}
                      >
                        Donate
                        <img
                          src={HeartIcon}
                          alt="Heart Icon"
                          className="ms-2"
                        />
                      </Link>
                    </div>
                  </div>
                </div>
              </LazyLoadComponent>
              <div className="col-md-6 col-lg-5 mb-5 mb-md-0">
                <div className="cta-box volunteer-cta text-center text-white rounded-25">
                  <StaticImage
                    style={{
                      gridArea: "1/1",
                      borderRadius: 25,
                      zIndex: 1,
                      content: "",
                      position: "absolute",
                      top: 0,
                      left: 0,
                      width: "100%",
                      height: "100%",
                    }}
                    layout="fullWidth"
                    placeholder="blurred"
                    // aspectRatio={3 / 1}
                    alt="Category Banner"
                    src={"../images/volunteer-cta-bg.png"}
                    formats={["auto", "webp", "avif"]}
                  />
                  <div className="content-wrapper" style={{ padding: 0 }}>
                    <h2 className="font-weight-bold">Join Us</h2>
                    <p className="mt-4 mb-5">
                      Thank you for helping us inspire our youth to feel alive.
                      Collectively, we are building a Foundation for their
                      future. 
                    </p>
                    <Link
                      className="btn btn-primary volunteer-btn pulse btn-lg btn-icon d-flex justify-content-center align-items-center mx-auto"
                      role="button"
                      to="/volunteer/"
                      style={{ width: 186, height: 54, fontWeight: 600 }}
                    >
                      Volunteer
                      <img
                        src={VolunteerIcon}
                        alt="Volunteer Icon"
                        className="ms-2"
                      />
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
        {/* </LazyLoadComponent> */}
        {/* <LazyLoadComponent> */}
        <div id="emblem" className="emblem-main" />
        <BgImage image={emblemImg} className="w-100 h-100">
          <div className="emblem-section py-spacing">
            <div className="container text-white">
              <div className="row flex-column-reverse align-items-center flex-md-row">
                <div className="col-md-6 mb-5 mb-md-0">
                  <span
                    className="text-uppercase text-lightblue"
                    style={{ fontWeight: 700 }}
                  >
                    Symbols
                  </span>
                  <h2 className="display-3 mb-5" style={{ fontWeight: 800 }}>
                    Our Emblem
                  </h2>
                  <div className="d-grid gap-4">
                    <p className="lead">
                      The emblem of the FLUO Foundation visually crystallizes
                      our unified commitment to the growth, guidance, community,
                      love, and power of transformation that is possible.
                    </p>
                  </div>
                </div>
                <div className="col-md-6 text-center p-5">
                  <StaticImage
                    src="../images/fluo-foundation-logo.png"
                    alt="Blog Post Image"
                    placeholder="blurred"
                    layout="constrained"
                    className="emblem-main img-fluid"
                    style={{ objectFit: "contain", width: 381, height: 380 }}
                  />
                </div>
              </div>
              <div className="pt-spacing">
                <h3 className="mb-5" style={{ fontWeight: 800 }}>
                  These are the symbols of the Emblem.
                </h3>
                <div className="emblem-accordion-wrapper rounded-25 p-5 position-relative">
                  <div className="row">
                    <div className="accordions">
                      {accordion.map((accordionItem, i) => (
                        <>
                          <AboutAccordion
                            accordionItem={accordionItem}
                            index={i}
                            toggleAccordion={toggleAccordion}
                          />
                        </>
                      ))}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </BgImage>
        {/* </LazyLoadComponent> */}
        <LazyLoadComponent>
          <div className="latest-posts-wrapper">
            <div className="container">
              <div className="pt-spacing position-relative">
                <BlogSwiperLatest latestPosts={latestPosts} />
              </div>
            </div>
          </div>
        </LazyLoadComponent>
      </div>
    </Layout>
  )
}

export default About

export const aboutPageQuery = graphql`
  query aboutPageQuery {
    aboutHeroImg: file(relativePath: { eq: "about-bg.jpeg" }) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    emblemImg: file(
      relativePath: { eq: "gradienta-t-Rt42Wl1RQ-unsplash.jpeg" }
    ) {
      childImageSharp {
        gatsbyImageData(
          layout: CONSTRAINED
          webpOptions: { quality: 70 }
          quality: 50
          width: 1920
          placeholder: DOMINANT_COLOR
          formats: [AUTO, WEBP, AVIF]
        )
      }
    }
    allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      edges {
        node {
          id
          title
          categories {
            title
          }
          slug {
            current
          }
          mainImage {
            asset {
              gatsbyImageData
            }
          }
          publishedAt(formatString: "MMMM DD, YYYY")
          authors {
            person {
              name
              slug {
                current
              }
            }
          }
        }
      }
    }
    categoryLists: allSanityPost(sort: { fields: publishedAt, order: DESC }) {
      group(field: categories___title) {
        fieldValue
        edges {
          node {
            id
            title
            excerpt
            publishedAt(formatString: "MMMM DD, YYYY")
            categories {
              title
            }
            slug {
              current
            }
            mainImage {
              asset {
                gatsbyImageData
              }
            }
            authors {
              person {
                name
                slug {
                  current
                }
              }
            }
          }
        }
      }
    }
  }
`
